.red_pocket_view {
  height: 100%;
  width: 100%;
  background-color: #f44336;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  

  .result {
    width: 100px;

    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 420px;
    }

    span {
      width: 240px;
      position: absolute;

      color: rgb(254, 234, 197);
      font-weight: bold;
      font-size: 21px;
    }
  }
}