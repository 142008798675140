.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  --title-color: #fff;
  --text-color: rgba(255, 255, 255, 0.60);
  
  --title-font-size: 28px;
  --deputy-title-font-size: 20px;
  --text-font-size: 14px;
  --deputy-text-font-size: 12px;

  --title-font-weight: 700;
  --deputy-title-font-weight: 600;
  --text-font-weight: 500;
  --deputy-text-font-weight: 400;
  overflow-y: auto;
  
}

.App::-webkit-scrollbar {
  display: none;
}

