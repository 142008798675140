.payment_view {
  display: flex;
  flex-direction: column;
  padding: 25px 16px 16px 16px;
  height: 100%;
  background-image: url('../../assets/payment/payment_bg.png');
  background-size: contain;
  overflow: hidden;


  .title {
    display: flex;
    justify-content: space-between;
    color: #000;
    font-size: 14px;
    font-weight: 700;
    text-align: left;

    .service {
      display: flex;
      align-items: center;
      gap: 3px;
      color: rgba(0, 0, 0, 0.60);
      font-weight: 500;
      font-size: 12px;
    }
  }

  .introduce {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 2.9vh;

    .item {
      color: rgba(0, 0, 0, 0.60);
      font-weight: 500;
      font-size: 12px;
      display: flex;
      align-items: center;
      gap: 1.7vh;

      .miniprogress {
        color: #FE6F9B;
        margin: 0 2px;
      }

      .activity {
        display: flex;
        align-items: center;
        margin: 0 4px;
      }

      .credit {
        color: #000;
        display: flex;
        align-items: center;
        gap: 5px;
        
        .icon {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  .overlay {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    color: #fff;
  }
}