.option_panel {
  position: relative;
  margin-top: 2.5vh;
  flex: 1;
  border-radius: 24px;
  background: rgba(0, 0, 0, 0.03);
  backdrop-filter: blur(30px);
  padding: 16px;

  display: flex;
  flex-direction: column;

  .payment_content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    gap: 2vh;
  }

  .loading {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .header {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 500;
    font-size: 12px;
    color: rgba(58, 25, 25, 0.6);

    .title {
      color: #000;
      font-size: 14px;
    }
  }

  input {
    height: 48px;
    outline: none;
    width: calc(100% - 32px);
    border: none;
    display: flex;
    align-items: center;
    padding: 0 16px;
    color: rgba(0, 0, 0, 0.40);
    font-size: 14px;
    font-weight: 500;
    border-radius: 12px;
    background: rgba(0, 0, 0, 0.05);
  }

  span {
    text-align: left;
    font-size: 12px;
  }

  .payment_options {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-row-gap: 8px;
    grid-column-gap: 8px;

    .option {
      display: flex;
      flex-direction: column;
      gap: 8px;
      position: relative;
      width: 100%;
      height: 80px;
      border-radius: 12px;
      background: rgba(0, 0, 0, 0.05);
      align-items: center;
      justify-content: center;

      .credit {
        display: flex;
        align-items: center;
        gap: 4px;
        color: #000;
        font-size: 20px;
        font-weight: 700;

        img {
          width: 24px;
          height: 24px;
        }
      }

      .discount {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px 12px 0px 8px;
        background: #F16868;
        font-size: 10px;
        color: #FFF;
        font-weight: 700;
        padding: 0 6px;
      }

      .price {
        color: rgba(0, 0, 0, 0.60);
        font-size: 12px;
        font-weight: 400;
      }
    }
  }

  .footer {
    margin-top: 3vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.6vh;

    .payment_btn {
      height: 48px;
      border-radius: 20px;
      background: #FE6F9B;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #FFF;
      font-size: 16px;
      font-weight: 500;
    }

    .agreement {
      color: rgba(0, 0, 0, 0.60);
      font-weight: 400;
      font-size: 10px;
    }
  }
}

.rv-action-sheet__header {
  font-size: 16px;
  color: #573B3B;
  font-weight: 700;
  border-bottom: 1px solid #F2F2F2;

  svg {
    position: absolute;
    top: 15px;
    right: 20px;
  }
}