.home_view {
  background-color: #FE6F9B;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .img {
    width: 100%;
  }
  
  .footer {
    padding: 30px;
  }
}