.payment_sheet {
  position: relative;
  height: 60vh;
  width: 100%;
  display: flex;
  flex-direction: column;

  .price {
    font-size: 40px;
    color: #573B3B;
    font-weight: 700;
    text-align: center;
    margin-top: 5.6vh;
  }

  .payment_selects {
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    margin-top: 5.4vh;

    .select_item {
      border-bottom: 1px solid #F2F2F2;
      padding: 12px 4px;
      display: flex;
      justify-content: space-between;

      .banner {
        display: flex;
        align-items: center;
        gap: 8px;
        color: #573B3B;
        font-weight: 400;
        font-size: 12px;
      }
    }
  }

  .payment_btn {
    position: absolute;
    bottom: 46px;
    left: 50%;
    width: calc(100% - 32px);
    transform: translateX(-50%);
    height: 48px;
    border-radius: 20px;
    background: #FE6F9B;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-size: 16px;
    font-weight: 500;
  }
}

.rv-dialog {
  padding: 20px 16px;
  width: calc(100vw - 46px);
}

.rv-dialog__content--isolated {
  height: 28.32vh;
}

.rv-dialog__message {
  color: #000;
  font-size: 16px;
  font-weight: 500;
}

.rv-dialog__footer {
  gap: 26px;
}

.rv-button {
  border-radius: 20px;
  background: #FE6F9B;
  box-shadow: 0px 0px 12px 0px rgba(204, 103, 103, 0.15);
  font-weight: 700;
  font-size: 16px;
  color: #fff !important;
}

.rv-dialog__cancel {
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.10) !important;
  color: rgba(0, 0, 0, 0.80) !important;
  font-size: 16px;
  font-weight: 700;
}

